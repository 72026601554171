import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocationBaseService {
  //public baseUrl="http://hrms.eastdelta.edu.bd"+"/"
  public baseUrl=location.origin+"/"
  constructor()
  {

    this.baseUrl="https://hrms.eastdelta.edu.bd/"
    if(location.origin=="http://localhost:4200")
    {

      this.baseUrl="http://localhost:10721"+"/"

    }
  }

  get_accyear()
  {
  return    localStorage.getItem("acc_year");


  }

}
