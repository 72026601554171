import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// Bootstrap DataTable
import { DataTablesModule } from 'angular-datatables';
import { ToastrModule } from 'ngx-toastr';
import { BodyContainerComponent } from './body-container/body-container.component';
import { DashboardIndexComponent } from './dashboard-index/dashboard-index.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { LoadingBarRouterModule, } from '@ngx-loading-bar/router';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { HttpClientModule, HttpErrorResponse } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {MatInputModule} from '@angular/material/input';
import {MatDialogModule} from '@angular/material/dialog';
import { MatButtonModule, MatIconModule, MatToolbarModule } from '@angular/material';
import { NgSelectModule } from '@ng-select/ng-select';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { NgImageSliderModule } from 'ng-image-slider';
import { NgxSpinnerModule, NgxSpinnerService } from "ngx-spinner";
import { SidebarComponent } from './sidebar/sidebar.component';
import { HeaderComponent } from './header/header.component';

import { JwtModule } from "@auth0/angular-jwt";
import { LocationBaseService } from './location-base.service';
import { ErrorpagesModule } from './errorpages/errorpages.module';
import { NgxSpinner, Size } from 'ngx-spinner/lib/ngx-spinner.enum';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { SumPipe } from './utility/sum.pipe';
import { FilterPipe } from './utility/filter.pipe';
export function tokenGetter() {

  return localStorage.getItem("token");
}
export function baseUrl() {

  let url=location.origin;
  if(location.origin=="http://localhost:4200"){
    url="localhost:10721"
  }
  return url;
}

class MyErrorHandler implements ErrorHandler {


  handleError(_error:HttpErrorResponse,) {

    if(_error.status==401){
      alert('Unauthorized');
      localStorage.clear();
      location.href="/dashboard"
    }

    if(_error.name=='HttpErrorResponse'){
      alert('something is wrong . please try again')
    }
    console.log(_error)
   //localStorage.clear();

   //  this.loaderService.hide();

    // do something with the exception
  }
}

const url=new LocationBaseService();
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    BodyContainerComponent,
    SidebarComponent,
    DashboardIndexComponent,
    LoginComponent,
    ],
  imports: [
    BrowserModule,NgSelectModule,NgImageSliderModule,NgxSpinnerModule,
    BrowserAnimationsModule,
    MatProgressBarModule,
    ErrorpagesModule,
    AppRoutingModule,
    LoadingBarRouterModule,
    LoadingBarModule,
    DataTablesModule,
    HttpClientModule,
    FormsModule,

    MatDatepickerModule,MatNativeDateModule,MatInputModule,MatDialogModule,MatToolbarModule,MatIconModule,MatButtonModule,
    ToastrModule.forRoot(
      {

        positionClass: 'toast-top-center',
        preventDuplicates: true,
        progressBar:true,
        enableHtml:true,
        disableTimeOut:false

      }
    ),
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        allowedDomains:[baseUrl()],disallowedRoutes:[],

      }
    })

  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [{provide: ErrorHandler, useClass: MyErrorHandler}],
  bootstrap: [AppComponent]
})
export class AppModule { }
